import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Article from '../components/article';

const TagIndex = ({ data, location }: { data: any; location: any }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const tags = data.allMarkdownRemark.group;

  return (
    <Layout>
      <SEO title="All tags" />

      <Article>
        <header className="pb-2">
          <h2>All tags</h2>
        </header>
        <ul>
          {tags.map((tag: any) => {
            return (
              <li key={tag.fieldValue}>
                <Link to={`/tag/${tag.fieldValue}`}>{tag.fieldValue}</Link>

                <small> — {tag.totalCount}</small>
              </li>
            );
          })}
        </ul>
      </Article>
    </Layout>
  );
};

export default TagIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
